import { LoadingCell } from '@kinsta/loading';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useExpanded, usePagination, useTable } from 'react-table';

import animationVariants from '@/src/animations';
import { Arrow } from '@/src/components';
import Pagination from '@/src/components/Table/Pagination';
import * as Styled from '@/src/components/Table/Table.style';
import { zIndexes } from '@/src/vars';
import ColumnFilter from './ColumnFilter/ColumnFilter';

export type StickyPositions = 'left' | 'right';

export type MKColumn = {
	title: string | JSX.Element;
	dataIndex: string;
	sticky?: StickyPositions;
};

type Sortable = {
	column: string;
	order: string;
};

type Sort = Sortable[];

type Props = {
	columns: MKColumn[];
	dataSource?: {
		[key: string]: unknown;
	}[];
	filter?: string;
	filters?: string[];
	hasTopBorder?: boolean;
	faker?: () => any;
	page?: number;
	pageSize?: number;
	hidePagination?: boolean;
	renderDrawer?: (row: any) => any;
	onPageChange?: (newPageIndex: number) => void;
	onSortClick?: (sort: Sort) => void;
	totalCount?: number;
	emptyMessage?: string;
	sort?: Sort;
	showColumnFilter?: boolean;
};

const Table = ({
	columns: columnsProp,
	dataSource: data,
	filter,
	hasTopBorder,
	page: _page,
	pageSize = 5,
	hidePagination,
	renderDrawer,
	onPageChange,
	onSortClick,
	totalCount,
	emptyMessage = 'No Data Available',
	sort,
	showColumnFilter,
}: Props) => {
	const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
	const columns: MKColumn[] = useMemo(
		() =>
			showColumnFilter
				? [
						...columnsProp,
						{
							title: (
								<ColumnFilter
									columns={columnsProp}
									hiddenColumns={hiddenColumns}
									setHiddenColumns={setHiddenColumns}
								/>
							),
							dataIndex: 'table-column-filter',
						},
				  ]
				: columnsProp,
		[columnsProp, showColumnFilter, hiddenColumns],
	);
	const dataLength = totalCount ?? data?.length ?? 1;
	const _pageCount = Math.ceil(dataLength / pageSize) || 1;

	const [dataCache, setDataCache] = useState(data ?? []);
	const [pageCountCache, setPageCountCache] = useState(_pageCount);

	const loadingColumns = columns.map(({ dataIndex }) => {
		return { [dataIndex]: <LoadingCell style={{ margin: '10px 0' }} /> };
	});
	const loadingRow = Object.assign({}, ...loadingColumns);
	const loadingRows = Array(pageSize).fill(loadingRow);

	useEffect(() => {
		if (data?.length === 0) {
			setDataCache([]);
		}
		if (data) {
			setDataCache(data);
			setPageCountCache(_pageCount);
		}
		const delay = setTimeout(() => {
			!data && setDataCache(loadingRows);
		}, 200);

		return () => {
			clearTimeout(delay);
		};
	}, [data, loadingRows, _pageCount]);

	const reactTableColumns = useMemo(() => {
		return columns.map((column) => {
			return {
				...column,
				Header: column.title,
				accessor: column.dataIndex,
			};
		});
	}, [columns]);

	const useTablePlugins = [
		...(renderDrawer ? [useExpanded] : []),
		usePagination,
	];

	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		headers,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		gotoPage,
		setHiddenColumns: setHiddenColumnsReactTable,
		state: { pageIndex },
	} = useTable(
		{
			columns: reactTableColumns,
			data: dataCache ?? [],
			initialState: {
				pageIndex: _page ?? 0,
				pageSize,
				globalFilter: filter,
			},
			manualPagination: true,
			pageCount: pageCountCache,
			autoResetHiddenColumns: false,
		},
		...useTablePlugins,
	);

	useEffect(() => {
		setHiddenColumnsReactTable(hiddenColumns);
	}, [hiddenColumns, setHiddenColumnsReactTable]);

	useEffect(() => {
		onPageChange?.(pageIndex);
	}, [pageIndex, onPageChange]);

	const isExpandable = Boolean(renderDrawer);

	return (
		<Styled.TableClip>
			<Styled.TableWrap>
				<Styled.Table {...getTableProps()} cellSpacing='0' cellPadding='0'>
					<thead>
						<tr>
							{isExpandable && (
								<Styled.Th
									isClickable={false}
									style={{ padding: 0 }}
								></Styled.Th>
							)}
							{headers.map((column: any) => {
								if (!column.isVisible) {
									return null;
								}

								if (column.dataIndex === 'table-column-filter') {
									return column.render('Header');
								}

								const isSortable = onSortClick && column?.sort && sort;

								return (
									<Styled.Th
										hasTopBorder={hasTopBorder}
										isClickable={isSortable}
										sticky={column.sticky}
										key={column.render('Header')}
										onClick={() => {
											if (onSortClick && column?.sort && sort) {
												onSortClick([
													{
														column: column.sort[0].column,
														order: sort?.[0].order === 'desc' ? 'asc' : 'desc',
													},
													...sort.slice(1).map((x: Sortable) => {
														return {
															...x,
															order: x.order === 'desc' ? 'asc' : 'desc',
														};
													}),
												]);
											}
										}}
									>
										<Styled.ColumnHeader>
											<Styled.Header>{column.render('Header')}</Styled.Header>

											{isSortable &&
												column?.sort?.[0]?.column === sort?.[0].column && (
													<Styled.SortIndicator>
														{sort?.[0].order === 'desc' ? '↓' : '↑'}
													</Styled.SortIndicator>
												)}
										</Styled.ColumnHeader>
									</Styled.Th>
								);
							})}
						</tr>
					</thead>

					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							const rowProps = row.getRowProps();

							return (
								<Fragment key={rowProps.key}>
									{isExpandable && (
										<Styled.HoverableTr>
											{isExpandable && (
												<Styled.Td
													style={{
														paddingRight: 0,
														userSelect: 'none',
													}}
												>
													<Styled.RenderDrawerControl
														onClick={() => {
															return row.toggleRowExpanded(!row.isExpanded);
														}}
														style={{ lineHeight: row.isExpanded ? 0 : '1px' }}
													>
														{row.isExpanded ? '-' : '+'}
													</Styled.RenderDrawerControl>
												</Styled.Td>
											)}
											{row.cells.map((cell, i) => {
												return (
													<Styled.Td
														{...cell.getCellProps()}
														key={cell.column.id}
														expandableRow={true}
														sticky={columns[i]?.sticky}
													>
														<Styled.TdClickable
															{...row.getToggleRowExpandedProps()}
															title='Toggle Dropdown'
														/>
														<Styled.TdContent>
															{cell.render('Cell')}
														</Styled.TdContent>
													</Styled.Td>
												);
											})}
										</Styled.HoverableTr>
									)}

									{!isExpandable && (
										<Styled.HoverableTr>
											{row.cells.map((cell, i) => {
												return (
													<Styled.Td
														{...cell.getCellProps()}
														key={i}
														sticky={columns[i]?.sticky}
													>
														{cell.render('Cell')}
													</Styled.Td>
												);
											})}
										</Styled.HoverableTr>
									)}

									<AnimatePresence>
										{row.isExpanded && (
											<tr>
												<td colSpan={999} style={{ width: '100%' }}>
													<motion.div
														initial='initial'
														animate='default'
														exit='exit'
														variants={animationVariants.slideOutUpSlideInDown}
														style={{ overflow: 'hidden' }}
													>
														<Styled.Drawer>
															<div style={{ zIndex: zIndexes.sm }}>
																{renderDrawer?.(row)}
															</div>
														</Styled.Drawer>
													</motion.div>
												</td>
											</tr>
										)}
									</AnimatePresence>
								</Fragment>
							);
						})}
					</tbody>
					{Boolean(data?.length === 0) && (
						<tfoot>
							<tr>
								<Styled.Td colSpan={999} style={{ textAlign: 'center' }}>
									{emptyMessage}
								</Styled.Td>
							</tr>
						</tfoot>
					)}
				</Styled.Table>
			</Styled.TableWrap>

			{!hidePagination && Boolean(data?.length !== 0) && pageCountCache > 1 && (
				<Styled.Pagination>
					{canPreviousPage ? (
						<Arrow animate={true} direction='left' onClick={previousPage} />
					) : (
						<Arrow animate={true} direction='left' disabled />
					)}

					<nav role='navigation' aria-label='Data table pagination'>
						<Styled.PaginationList>
							<Pagination
								pageIndex={pageIndex}
								pageCount={pageCountCache}
								gotoPage={gotoPage}
							/>
						</Styled.PaginationList>
					</nav>

					{canNextPage ? (
						<Arrow animate={true} direction='right' onClick={nextPage} />
					) : (
						<Arrow animate={true} direction='right' disabled />
					)}
				</Styled.Pagination>
			)}
		</Styled.TableClip>
	);
};

export default Table;
